import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {styled} from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const Popup = ({open, onDialogClose, title, dialogActions, children}) => {
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onDialogClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="lg"
                style={{maxHeight: '100vh'}}
            >
                <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >

                    <CloseIcon/>

                </IconButton>
                <DialogContent>
                    {children}
                </DialogContent>
                {dialogActions &&
                    <DialogActions sx={{justifyContent: "center"}}>
                        {dialogActions}
                    </DialogActions>
                }
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Popup

import './App.css';
import Home from "./Component/Home";
import HamburgerMenu from "./Component/HamburgerMenu";

function App() {


  return (
      <div className={"App"}>
          <Home/>
      </div>
  );
}

export default App;
